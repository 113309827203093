import { useAuthContext } from './AuthContextProvider';
import React, { useEffect, useState } from 'react';
import {changeEmailProfilePassword, updateProfile} from '../main/API';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import { Alert, Button, Card, Form } from 'react-bootstrap';
import { CommonDate } from '../shared/Dates';

export const ResetPasswordCard = ({email, onClose}) => {
  const [changePasswordMessage, setChangePasswordMessage] = useState(null);

  const sendInstructions = () => {
    if (!email) {
      setChangePasswordMessage('Password reset can work only when an email is used to login.');
      return;
    }
    changeEmailProfilePassword(email)
      .then(() => setChangePasswordMessage(`An e-mail has been sent with instructions on resetting your password.`))
      .catch(e => {
        setChangePasswordMessage('Could not initiate the password reset, please try again later.');
        console.error('Password reset failure', e);
      });
  };

  return (<Card>
    <Card.Body>
      <Card.Title data-testid="password-reset-title">Change password</Card.Title>
      { changePasswordMessage && <>
        <Alert variant="info" data-testid="password-reset-text" className="mt-3">{changePasswordMessage}</Alert>
        <Button variant="primary" data-testid="password-reset-btn-ok" type="button" onClick={() => setChangePasswordMessage(null)}>OK</Button>
      </> }
      {!changePasswordMessage && <>
        {!email && <Card.Text data-testid="password-reset-text">There needs to be an email set but none was found.</Card.Text>}
        {email && <>
          <Card.Text data-testid="password-reset-text">In order to change the password you will need to follow instructions.
          Instructions will be sent to your email. Do you want to proceed?</Card.Text>
          <Button variant="primary" data-testid="password-reset-btn-send" type="button" onClick={() => sendInstructions()}>Send instructions</Button>
        </>}
        <Button variant="secondary" data-testid="password-reset-btn-cancel" type="button" className="ml-2" onClick={() => onClose && onClose()}>Cancel</Button>
      </>}
    </Card.Body>
  </Card>);
};

export const AccountDetailsCard = () => {
  const {user, refreshUser} = useAuthContext();

  const [isProfileEditVisible, setIsProfileEditVisible] = useState(false);
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isPasswordChangeVisible, setIsPasswordChangeVisible] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [postcode, setPostcode] = useState("");

  useEffect(() => {
    setName(user.name || '');
    setEmail(user.email || '');
    setPostcode(user.postcode || '');
  }, [user]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      updateProfile(name, email, postcode)
        .then(() => refreshUser())
        .then(() => {
          if ((user.email || '') !== (email || ''))
            setSuccessMessage("A verification email has been sent. Please follow the instructions in this message to verify your new address.");
          else
            setSuccessMessage("Details were updated");
          setErrorMessage(null);
        })
        .catch(e => {
          setSuccessMessage(null);
          setErrorMessage(parseAxiosError(e, 'Failed to retrieve results from server').getMessage());
        });
    }
    setValidated(true);
  };

  if(isProfileEditVisible) return (<Card>
    <Card.Body>
      <Card.Title>Update details</Card.Title>
      {!successMessage && <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control required value={name} onChange={evt => setName(evt.target.value)}/>
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label>Contact email</Form.Label>
          <Form.Control type="email" value={email} onChange={evt => setEmail(evt.target.value)}/>
        </Form.Group>
        <Form.Group controlId="postcode">
          <Form.Label>Postcode</Form.Label>
          <Form.Control required value={postcode} onChange={evt => setPostcode(evt.target.value)}/>
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
        <Button variant="outline-secondary" type="button" className="ml-2" onClick={() => setIsProfileEditVisible(false)}>
          Cancel
        </Button>
      </Form>}

      {successMessage && <>
        <Alert variant="success" className="mt-3">{successMessage}</Alert>
        <Button variant="primary" type="button" onClick={() => {
          setIsProfileEditVisible(false);
          setSuccessMessage(null);
        }}>OK</Button>
      </>}
      {errorMessage && <Alert variant="danger" className="mt-3">{errorMessage}</Alert>}
    </Card.Body>
  </Card>);

  if (isPasswordChangeVisible) return <ResetPasswordCard email={user.emailProfileEmail} onClose={() => setIsPasswordChangeVisible(false)} />;

  return (<Card>
    <Card.Body>
      <Card.Title>Details</Card.Title>
      {user.imageUrl && <Card.Text>Profile picture <img alt="Logged in user" className="img-rounded img-responsive" src={user.imageUrl}/></Card.Text>}
      {user.name && <Card.Text>Name: {user.name}</Card.Text>}
      {user.email && <Card.Text>Contact email: {user.email}</Card.Text>}
      {user.emailProfileEmail && <Card.Text>Login email: {user.emailProfileEmail}</Card.Text>}
      <Card.Text>Joined on: <CommonDate date={user.registrationTime}></CommonDate></Card.Text>
      {user.postcode && <Card.Text>Postcode: {user.postcode}</Card.Text>}
      {user.roleNames && user.roleNames.length && <Card.Text>
        {user.roleNames.map(r => <span key={r} className="badge badge-info mr-2">{r}</span>)}
      </Card.Text>}
    </Card.Body>
    <Card.Footer>
      <Button variant="outline-secondary" type="button" className="ml-auto" onClick={() => setIsProfileEditVisible(true)}>
        Edit details
      </Button>
      { user.emailProfileEmail && <Button variant="outline-secondary" type="button" className="ml-2" onClick={() => setIsPasswordChangeVisible(true)}>
        Change password
      </Button> }
    </Card.Footer>
  </Card>);
};

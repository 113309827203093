import React, {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import {HomePage} from '../pages/HomePage';
import {useIntl} from 'react-intl';
import {AccountDefaultSecured} from '../pages/AccountDefault';
import {AssessmentPageSecured} from '../pages/AssessmentPage';
import {AssessmentsPageSecured} from '../pages/AssessmentsPage';
import {AssessmentAreaPageSecured} from '../pages/AssessmentAreaPage';
import {AssessmentSubmitterPageSecured} from '../pages/AssessmentSubmitterPage';
import {AssessmentPreviewPageSecured} from "../pages/AssessmentPreviewPage";
import {AssessmentSubmitConfirmPageSecured} from "../pages/AssessmentSubmitConfirmPage";
import {AssessmentSuggestionsPageSecured} from "../pages/AssessmentSuggestionsPage";
import {HelpPage} from "../pages/HelpPage";
import {CookiePolicyPage} from "../pages/CookiePolicyPage";
import {AccessibilityPage} from "../pages/AccessibilityPage";

const RouteWithTitle = ({title, ...rest}) => {
  const intl = useIntl();
  useEffect(() => {
    document.title = intl.formatMessage({id: title});
  }, [intl, title]);
  return <Route {...rest} />;
};

export const AppRoutes = () => {
  return (<Switch>
    <RouteWithTitle exact={true} title="general.brand" path="/" component={HomePage}/>
    <RouteWithTitle exact={true} title="nav.account" path='/account' component={AccountDefaultSecured}/>
    <RouteWithTitle exact={true} title="nav.assessments" path="/assessment" component={AssessmentsPageSecured}/>
    <RouteWithTitle exact={true} title="nav.assessment" path="/assessment/:formId" component={AssessmentPageSecured}/>
    <RouteWithTitle exact={true} title="nav.assessment" path="/assessment/:formId/area/:area" component={AssessmentAreaPageSecured}/>
    <RouteWithTitle exact={true} title="nav.assessment" path="/assessment/:formId/submitter" component={AssessmentSubmitterPageSecured}/>
    <RouteWithTitle exact={true} title="nav.assessment.preview" path="/assessment/:formId/preview" component={AssessmentPreviewPageSecured}/>
    <RouteWithTitle exact={true} title="nav.assessment.submitted" path="/assessment/:formId/submitted" component={AssessmentSubmitConfirmPageSecured}/>
    <RouteWithTitle exact={true} title="nav.assessment.suggestions" path="/assessment/:formId/suggestions" component={AssessmentSuggestionsPageSecured}/>
    <RouteWithTitle exact={true} title="nav.help" path="/help" component={HelpPage}/>
    <RouteWithTitle exact={true} title="nav.cookies" path='/cookie-policy' component={CookiePolicyPage}/>
    <RouteWithTitle exact={true} title="nav.accessibility" path='/accessibility-statement' component={AccessibilityPage}/>
    <Route component={HomePage}/>
  </Switch>);
};

import { ReactComponent as DefaultLogo } from './logo-white.svg';
import { ReactComponent as KentLogo } from './kent-logo.svg';
import { FormattedMessage } from 'react-intl';

const logoOverride = (flavour) => {
  switch (flavour) {
    case 'kent': return <>
      <DefaultLogo className="customer-logo" height="35" width="92" alt="Logo" />&nbsp;&nbsp;
      <KentLogo className="customer-logo" height="49" width="64" alt="Logo" />
    </>;
    default: return <>
      <DefaultLogo className="customer-logo" height="35" width="92" alt="Logo" />
      <div className="text-secondary text-tiny text-left text-bright"><FormattedMessage id="general.flavour"/></div>
    </>;
  }
};

export default logoOverride;

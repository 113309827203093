import React, { useContext, useState } from 'react';

/**
 * Glues some of the high level parts of application: flavour config, sidebar toggler
 * and exposes those for use in underlying components.
 * @type {React.Context<{}>}
 */
export const AppState = React.createContext({});
export const useAppState = () => useContext(AppState);
export const AppStateProvider = ({ children }) => {

  const [sidebarActive, setSidebarActive] = useState(false);

  /**
   * Show/hide sidebar when on small screens
   */
  const toggleSidebarActive = () => setSidebarActive(!sidebarActive);

  return (
    <AppState.Provider value={{
      sidebarActive,
      toggleSidebarActive
    }}>
      {children}
    </AppState.Provider>
  );
};

import React from 'react';
import {Page, PageNav} from "../shared/Page";
import { FormattedMessage, useIntl } from 'react-intl';

export function AccessibilityPage () {
  const intl = useIntl();
  return <>
    <Page>
      <PageNav title="nav.accessibility"/>
      <div className="space-1 row">
        <div className=" col-lg-8">
          <p>This accessibility statement applies to content published on the bettercare.org.uk domain.</p>
          <p>This website is run by Looking Local on behalf of <FormattedMessage id="accessibility.organisation" />. It is designed to be used by as many people as possible. The text should be clear and simple to understand. You should be able to:</p>
          <ul>
            <li>change colours, contrast levels and fonts using browser or device settings</li>
            <li>zoom in up to 300% without the text spilling off the screen</li>
            <li>navigate most of the website using a keyboard or speech recognition software</li>
            <li>listen to most of the website using a screen reader (including the most recent versions of JAWS, NVDA and VoiceOver)</li>
          </ul>
          <p><a href="https://mcmw.abilitynet.org.uk/">AbilityNet</a> has advice on making your device easier to use if you have a disability.</p>
          <br />
          <h4>How accessible this website is</h4>
          <p>Parts of this website are not fully accessible. For example:</p>
          <ul>
            <li>some third-party components do not use accessible names or identities (for example: YouTube controls)</li>
            <li>the log in and sign up pages are difficult to navigate for people that cannot use a mouse</li>
            <li>some older PDF documents are not fully accessible to screen reader software</li>
          </ul>
          <br />
          <h4>Feedback and contact information</h4>
          <p><a href={intl.formatMessage({id: "accessibility.contact"})}>Tell us if you need information in a different format or language</a>.</p>
          <p>In your message, include:</p>
          <ul>
            <li>the web address (URL) of the content</li>
            <li>your email address and name</li>
            <li>the format or language you need - for example: plain text, braille, BSL, large print, audio CD, or a language other than English</li>
          </ul>
          <p><FormattedMessage id="accessibility.response" /></p>  
          <br />  
          <h4>Reporting accessibility problems with this website</h4>
          <p>We’re always looking to improve the accessibility of this website. If you find any problems not listed on this page or think we’re not meeting accessibility requirements, please <a href="mailto:accessibility@lookinglocal.gov.uk">contact us</a> describing the issue and where it is located on the site.</p>
          <br />
          <h4>Enforcement procedure</h4>
          <p>The Equality and Human Rights Commission (EHRC) is responsible for enforcing the Public Sector Bodies (Websites and Mobile Applications) (No.2) Accessibility Regulations 2018 (the ‘accessibility regulations’).</p>
          <p>If you contact us with a complaint and you’re not happy with our response <a href="https://www.equalityadvisoryservice.com">contact the Equality Advisory and Support Service (EASS)</a>.</p>
          <br />
          <h4>Technical information about this website’s accessibility</h4>
          <p>Looking Local is committed to making this website accessible, in accordance with the Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018.</p>
          <br />
          <h4>Compliance status</h4>
          <p>This website is partially compliant with the <a href="https://www.w3.org/TR/WCAG22">Web Content Accessibility Guidelines (version 2.2)</a> AA standard, due to the non-compliances listed below.</p>
          <br />
          <h4>Non-accessible content</h4>
          <p>The content listed below is non-accessible for the following reasons.</p>
          <h5>Non-compliance with the accessibility regulations</h5>
          <p>1. The ‘Full screen’ button within the YouTube iframe does not use discernible text. This fails WCAG 2.2 success criterion 4.1.2 (Name, Role, Value). This is a third-party component/dependency that we do not control.</p>
          <p>2. A keyboard-only user can become trapped inside the log in and sign up dialog windows. This fails WCAG 2.2 success criterion 2.1.2 (No Keyboard Trap)</p>
          <p>3. The ‘Volume panel’ within the YouTube iframe does not use an accessible name. This fails WCAG 2.2 success criterion 4.1.2 (Name, Role, Value). This is a third-party component/dependency that we do not control.</p>
          <h5>Disproportionate burden</h5>
          <p>Not applicable.</p>
          <h5>Content that’s not within the scope of the accessibility regulations</h5>
          <p>Not applicable.</p>
          <h5>PDFs and other documents</h5>
          <p>Some of the older PDFs available via our website do not meet accessibility standards - for example: they may not be structured so they’re accessible to a screen reader. This does not meet WCAG 2.2 success criterion 4.1.2 (Name, Role, Value).</p>
          <p>The PDFs available via our website are owned/published by other organisations. Where we host non-compliant documents, we will replace them when the third-party owner updates their documentation to meet accessibility standards.</p>
          <br />
          <h4>How we tested this website</h4>
          <p>We use the Web Content Accessibility Guidelines (version 2.2) level AA to test how accessible bettercare.org.uk is.</p>
          <p>This website was last tested for compliance with the Web Content Accessibility Guidelines (version 2.2) level AA on&nbsp;28 February 2024. The test was carried out internally by Looking Local.</p>
          <p>We use <a href="https://accessibilityinsights.io/docs/en/web/overview">Accessibility Insights for Web</a> and <a href="https://wave.webaim.org">WAVE</a> to automatically and manually test a subset of web pages, ensuring we include all the main areas of the website in the sample. Results are recorded and accessibility issues discovered are added to our accessibility roadmap.</p>
          <br />
          <h4>What we’re doing to improve accessibility</h4>
          <p>We conduct periodic accessibility testing and fix content which fails to meet the Web Content Accessibility Guidelines version 2.2 AA standard (as long as it falls under our control). We will also fix accessibility issues (under our control) reported to us by service users. We will fix any reported issues as soon as we can and update this page when they are fixed.</p>
          <br />
          <h4>Preparation of this accessibility statement</h4>
          <p>This statement was prepared on&nbsp;15 March 2021. It was last reviewed/updated on&nbsp;8 March 2024.</p>
        </div>
      </div>
    </Page>
  </>;
}

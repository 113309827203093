/**
 * https://github.com/ReactTraining/history/tree/master/docs
 */
import { createBrowserHistory, createMemoryHistory } from "history";
const RouterHistory = createBrowserHistory();

let timoutId;
let currentPath = window.location.pathname;
RouterHistory.listen(() => {
  // Debounce scroll to top behaviour
  if (timoutId != null) {
    clearTimeout(timoutId);
  }

  timoutId = setTimeout(() => {
    document.getElementById('root')
      .scrollIntoView({ behavior: 'smooth' });
  }, 500);

  // Send page change events to Google Analytics (if set)
  // react router emits duplicate events sometimes, hence the equality check
  const newPath = window.location.pathname;
  if (typeof window.gtag === 'function' && newPath !== currentPath) {
    currentPath = newPath;
    window.gtag('event', 'page_view', {'path': currentPath});
  }
});

function getURLSearchParams () {
  const urlSearch = RouterHistory.location.search;
  return new URLSearchParams(urlSearch);
}

function buildMemoryHistory() {
  return createMemoryHistory();
}

export {
  RouterHistory,
  getURLSearchParams,
  buildMemoryHistory
};

export default RouterHistory;

import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

export const PromptModal = ({ title, text, onCancel, onSuccess }) => {
  return <Modal size="sm" centered show onHide={() => onCancel()} backdrop="static" animation={false} data-testid="modal">
    <Modal.Header closeButton>
      <Modal.Title>
        {title}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {text}
    </Modal.Body>
    <Modal.Footer>
      <button type="button" data-testid="modal-cancel" className="btn btn-outline-secondary btn-sm px-3 mr-auto" onClick={onCancel}>Cancel</button>
      <button type="button" data-testid="modal-ok" className="btn btn-primary btn-sm px-3" onClick={onSuccess}>OK</button>
    </Modal.Footer>
  </Modal>;
};

PromptModal.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

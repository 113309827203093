import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Auth0ContextErrorPage } from './ContextErrorPage';
import {
  getToken,
  getUser,
  handleAuthRedirect,
  hasToken, hasValidToken,
  loginWithRedirect,
  logout,
  refreshSession,
  viewAccountPage
} from './authClient';
import {parseAxiosError} from "../shared/AxiosResponseErrorParser";

export const AuthContext = React.createContext({});
export const useAuthContext = () => useContext(AuthContext);
export const AuthContextProvider = ({
  children
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [initializationError, setInitializationError] = useState(null);

  /**
   * Should run only once as options do not change
   */
  useEffect(() => {
    const initAndGetAccessToken = async () => {
      if (window.location.search.includes('token=')) {
        await handleAuthRedirect();
      } else {
        const hasValid = await hasValidToken();
        if (hasValid) {
          try {
            await refreshSession();
            setIsAuthenticated(true);
            setToken(getToken());
            setUser(getUser());
          } catch (e) {
            const parsedError = parseAxiosError(e);
            if (parsedError.status === 404) {
              setIsAuthenticated(false);
              setToken(null);
              setUser(null);
            } else {
              throw e;
            }
          }
        } else {
          setIsAuthenticated(false);
          setToken(null);
          setUser(null);
        }
      }
    };

    initAndGetAccessToken()
      .then(() => setInitializing(false))
      .catch(e => {
        console.error('initAuth failure', e);
        setInitializing(false);
        setInitializationError(e);
      });

  }, []);

  const refreshUser = useCallback(() => {
    setUpdating(true);
    return refreshSession()
      .then(() => {
        if (hasToken()) {
          setIsAuthenticated(true);
          setToken(getToken());
          setUser(getUser());
        } else {
          setIsAuthenticated(false);
          setToken(null);
          setUser(null);
        }
        setUpdating(false);
      })
      .catch(() => {
        setIsAuthenticated(false);
        setToken(null);
        setUser(null);
        setUpdating(false);
      });
  }, [setUpdating, setIsAuthenticated, setToken, setUser]);

  return (
    <AuthContext.Provider
      value={{
        initializing,
        updating,
        isAuthenticated,
        token,
        user,
        refreshUser,
        loginWithRedirect: (...p) => loginWithRedirect(...p),
        logout: (...p) => logout(...p),
        viewAccountPage
      }}
    >
      {initializationError && <Auth0ContextErrorPage />}
      {!initializationError && children}
    </AuthContext.Provider>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

/**
 * Renders text "2 hours ago" with a tooltip showing "29 Apr 2021 at 12:18:26"
 * @param date UTC date string
 */
export const CasualTime = ({date, ...rest}) => {
  if (!date) return null;
  return (<OverlayTrigger placement="top" overlay={<Tooltip id={date}><CommonDateTime date={date} /></Tooltip>}>
    <span {...rest}>
      <Moment fromNow local={true} utc={true} date={date} />
    </span>
  </OverlayTrigger>);
};
CasualTime.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

/**
 * If withCasual==true renders CasualTime
 * If withCasual==false renders "29 Apr 2021 at 12:18:26"
 * If date==null renders fallback
 * @param date UTC date string
 * @param withCasual UTC date string
 * @param fallback render this in case date is null
 */
export const CommonDateTime = ({ date, withCasual = false, fallback = null }) => {
  if (!date) return fallback;
  const formal = <Moment local={true} utc={true} format="D MMM YYYY [at] HH:mm:ss" date={date} />;
  if (!withCasual)
    return formal;

  return <>{formal} (<CasualTime date={date} />)</>;
};
CommonDateTime.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  withCasual: PropTypes.bool
};

/**
 * Renders "29 Apr 2021"
 * If date==null renders fallback
 * @param date UTC date string
 * @param fallback render this in case date is null
 */
export const CommonDate = ({ date, fallback = null }) => {
  if (!date) return fallback;
  return <Moment local={true} utc={true} format="D MMM YYYY" date={date} />;
};
CommonDate.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
};

import React, { useState } from 'react';
import { deleteUserAccount } from '../main/API';
import { logout } from './authClient';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import { Alert, Card, Spinner } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';

const PromptDeleteAccountModal = ({ title, children, onCancel, onSuccess }) => {
  return <Modal data-testid="prompt-delete-account-modal" size="md" centered show onHide={() => onCancel()} backdrop="static" animation={false}>
    <Modal.Header closeButton>
      <Modal.Title>
        {title}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {children}
    </Modal.Body>
    <Modal.Footer className="justify-content-start">
      <button type="button" data-testid="yes-delete-btn" className="btn btn-outline-secondary btn-sm px-3" onClick={onSuccess}>Yes, delete</button>
      <button type="button" data-testid="cancel-btn" className="btn btn-primary btn-sm px-3" onClick={onCancel}>Cancel</button>
    </Modal.Footer>
  </Modal>;
};

export const AccountDeleteCard = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [userConfirmText, setUserConfirmText] = useState('');
  const [loading, setLoading] = useState(false);

  const handleDeleteUserAccount = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (userConfirmText.trim().toLowerCase() === 'i am sure') {
      setLoading(true);
      deleteUserAccount()
        .then(() => {
          logout();
        })
        .catch(e => {
          setLoading(false);
          setIsConfirmVisible(false);
          setErrorMessage(parseAxiosError(e, 'Failed to remove user account from server').getMessage());
        });
    }
  };

  return (<Card data-testid="account-delete-card" className="mt-4">
    <Card.Body>
      <Card.Title>Your right to be forgotten</Card.Title>
      <p>Stored within your BetterCare Support account is data relating to your activity in BetterCare Support, such as:</p>
      <ul>
        <li>personal details you have added to 'Personal details/About you' section;</li>
        <li>links to social media accounts you may have used to log in to BetterCare Support;</li>
        <li>data from forms you have completed through the 'Assessment' section;</li>
        <li>basic contact information you have added to the 'Account' section.</li>
      </ul>
      <p>We retain this data securely to improve your experience of BetterCare Support. For instance, you may wish to review information from previous assessments.</p>
      <p>If you wish to delete this data and your account, you may do so using the button below. All your data will be permanently erased from BetterCare Support.</p>
      <p>Please note: If you have submitted any assessments to your local council, your data will remain with them so you will need to contact them separately.</p>
      <p>You may re-register later with the same details, but none of your previously held information will be available.</p>
      <button data-testid="remove-btn" className="btn btn-outline-secondary" type="submit" onClick={() => setIsConfirmVisible(true)}>Delete my BetterCare Support account</button>
      {errorMessage && <Alert variant="danger" className="mt-3">{errorMessage}</Alert>}
      {isConfirmVisible &&
        <PromptDeleteAccountModal title="Your right to be forgotten" onCancel={() => setIsConfirmVisible(false)} onSuccess={(e) => handleDeleteUserAccount(e)}>
          <p>You are about to permanently delete your BetterCare Support account. If you really want to do this type the following in the box below...</p>
          <p><strong>I am sure</strong></p>
          <p className="text-danger">...and then select 'Yes, delete'</p>
          <input data-testid="input-confirmation" className="form-control" onChange={event => setUserConfirmText(event.target.value)}/>
          {loading && <div className="p-4 d-flex align-items-center justify-content-center">
            <Spinner animation={'border'} variant={'primary'}/>
          </div>}
        </PromptDeleteAccountModal>}
    </Card.Body>
  </Card>);
};

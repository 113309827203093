import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { NavBackArrow } from '../main/Icons';
import { FooterComponent } from './FooterComponent';

export const Page = ({className, children}) => {
  return (<>
    <div className={`container ${className || ''}`} role="main">
      {children}
    </div>
    <FooterComponent />
  </>);
};

export const PageNav = ({title, goBackUrl, children}) => {
  return (<>
    <div className="px-0" data-testid="page-nav">
      <nav className="navbar app-navbar p-0">
        <div className="navbar-brand page-title" data-testid="page-nav-title">
          {goBackUrl && <Link to={goBackUrl} aria-label="Go Back" className="btn p-0 m-0 ml-1 mr-2 d-inline-block">
            <NavBackArrow className="nav-back-arrow" size="28" />
          </Link>}
          <h1 id="main-content" tabIndex="-1" className="h5 m-0 d-inline-block"><FormattedMessage id={title} /></h1>
        </div>
        {children}
      </nav>
    </div>
  </>);
};

import { Page } from '../shared/Page';
import React from 'react';

export const NotFoundPage = () => {
  return (
    <Page>
      <div className="row align-items-start space-1">
        <div className="col-md-8 offset-md-2">
          <h1 className="text-center">Page was not found</h1>
        </div>
      </div>
    </Page>
  );
};

import Avatar from 'react-avatar';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * @return {Avatar}
 */
export const UserPicture = ({ size = '30', email, ...rest }) => {
  const nameForInitials = (email + '').split('@')[0].replace(/\W/, ' ');
  return <Avatar textSizeRatio={2.2} size={size} round={true} name={nameForInitials} email={email} maxInitials={2} {...rest} />;
};

UserPicture.propTypes = {
  ...Avatar.propTypes,
  email: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default UserPicture;

import React from 'react';
import {Page, PageNav} from '../shared/Page';
import {allCategorizedExternalLinks} from '../main/ExternalLinks';
import {FormattedMessage} from "react-intl";
import {ExternalLinkListItem} from "../shared/ExternalLink";

const categorizedLinks = allCategorizedExternalLinks();
const categories = Object.keys(categorizedLinks);
categories.sort();

export function HelpPage() {

  return (<>
    <Page>
      <PageNav title="nav.help"/>

      <div className="space-1">
        <h2 className="h5"><FormattedMessage id="services.all.title" /></h2>
        <p className="text-muted"><FormattedMessage id="services.all.description" /></p>

        <div className="card-columns">
          {categories.map(categoryName => <div key={categoryName} className="card">
            <div className="card-header">{categoryName}</div>
            <div className="list-group list-group-flush">
              {categorizedLinks[categoryName].map(externalLink =>
                <ExternalLinkListItem key={externalLink.url} externalLink={externalLink} />)}
            </div>
          </div>)}
        </div>
      </div>
    </Page>
  </>);
}

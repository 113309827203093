import React, { useState } from 'react';
import { Router } from 'react-router-dom';
import { useAuthContext } from '../auth/AuthContextProvider';
import axios from 'axios';
import { AppRoutes } from './AppRoutes';
import RouterHistory from './RouterHistory';
import Spinner from 'react-bootstrap/Spinner';
import { FormattedMessage } from 'react-intl';
import {MainNav} from './AppNav';
import {CookiePopup} from "../cookies/popup";

let axiosRequestInterceptorSetup;
let axiosResponseInterceptorSetup;
let bufferOf401PromiseResolvers = [];
let bufferOf403PromiseResolvers = [];

const App = () => {

  axios.defaults.baseURL = process.env.REACT_APP_SERVER;

  const { initializing, isAuthenticated, token, loginWithRedirect } = useAuthContext();
  const [dialog401Open, setDialog401Open] = useState(false);
  const [dialog403Open, setDialog403Open] = useState(false);
  const [dialogServerErrorOpen, setDialogServerErrorOpen] = useState(false);

  const ERROR = {
    unauthorized: 401,
    forbidden: 403,
    serverError: 500
  };

  /**
   * Can be used only after Auth0Client is ready!
   * @param config
   * @return {Q.Promise<any> | Promise<any>}
   */
  function axiosRequestInterceptor (config) {
    if (isAuthenticated && token) {
      config.headers = config.headers || {};
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    }

    return config;
  }

  /**
   * Can be used only after Auth0Client is ready!
   * @param error
   * @return {Q.Promise<any> | Promise<any>}
   */
  function axiosResponseFailureInterceptor (error) {
    if (error && error.response) {
      switch (error.response.status) {
        case ERROR.unauthorized:
          return new Promise(resolve => {
            bufferOf401PromiseResolvers.push(resolve);
            setDialog401Open(true);
          }).then(() => Promise.reject(error));
        case ERROR.forbidden:
          return new Promise(resolve => {
            bufferOf403PromiseResolvers.push(resolve);
            setDialog403Open(true);
          }).then(() => Promise.reject(error));
        default:
          if (error.response.status >= ERROR.serverError) {
            setDialogServerErrorOpen(true);
            // TODO send error to Airbrake
          }
          return Promise.reject(error);
      }
    } else {
      // Necessary to show errors without status codes like "Network Error"
      // most often its CORS issue
      setDialogServerErrorOpen(true);
    }

    return Promise.reject(error);
  }

  function closeDialog (errorType, reauthenticate) {
    switch (errorType) {
      case ERROR.unauthorized:
        while (bufferOf401PromiseResolvers.length > 0) {
          bufferOf401PromiseResolvers.shift().call();
        }
        setDialog401Open(false);
        break;
      case ERROR.forbidden:
        while (bufferOf403PromiseResolvers.length > 0) {
          bufferOf403PromiseResolvers.shift().call();
        }
        setDialog403Open(false);
        break;
      default:
        setDialog401Open(false);
        setDialog403Open(false);
        setDialogServerErrorOpen(false);
    }

    if (reauthenticate)
      loginWithRedirect();
  }

  if (axiosRequestInterceptorSetup != null) {
    axios.interceptors.request.eject(axiosRequestInterceptorSetup);
  }

  if (axiosResponseInterceptorSetup != null) {
    axios.interceptors.response.eject(axiosResponseInterceptorSetup);
  }

  if (!initializing) {
    axiosRequestInterceptorSetup = axios.interceptors.request.use(axiosRequestInterceptor);
    axiosResponseInterceptorSetup = axios.interceptors.response.use((resp) => resp, axiosResponseFailureInterceptor);
  }

  let apiErrorsIfAny = null;
  let api401ErrorIfAny = null;
  let api403ErrorIfAny = null;
  let apiServerErrorIfAny = null;

  if (dialog401Open) api401ErrorIfAny = (<div className="container">
    <div className="alert alert-danger mb-2">Authentication is required</div>
    <button className="btn btn-secondary mr-1" onClick={() => closeDialog(ERROR.unauthorized)}>Cancel</button>
    <button className="btn btn-secondary" onClick={() => closeDialog(ERROR.unauthorized, true)}>
      <FormattedMessage id="general.log_in"/>
    </button>
  </div>);

  if (dialog403Open) api403ErrorIfAny = (<div className="container">
    <div className="alert alert-danger mb-2">You're not authorized to perform an action</div>
    <button className="btn btn-secondary mr-1" onClick={() => closeDialog(ERROR.forbidden)}>Cancel</button>
    <button className="btn btn-secondary" onClick={() => closeDialog(ERROR.forbidden, true)}>
      <FormattedMessage id="general.log_in"/>
    </button>
  </div>);

  if (dialogServerErrorOpen) apiServerErrorIfAny = (<div className="container">
    <div className="alert alert-danger mb-2">Server responded with an unexpected error, please try reloading the page after a couple of minutes to see if this error goes away.
      <button type="button" onClick={() => closeDialog(ERROR.serverError)} className="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>);

  if (api401ErrorIfAny || api403ErrorIfAny || apiServerErrorIfAny) {
    apiErrorsIfAny = (<div className="bg-dark p-3" data-testid="app-error">
      {api401ErrorIfAny}
      {api403ErrorIfAny}
      {apiServerErrorIfAny}
    </div>);
  }

  let timoutId;
  RouterHistory.listen(() => {
    document.activeElement.blur();
    if (timoutId != null) {
      clearTimeout(timoutId);
    }
    timoutId = setTimeout(() => {
      document.getElementById('root')
        .scrollIntoView({ behavior: 'smooth' });
    }, 500);
  });

  if (initializing) return (<div data-testid="app-loader" className="d-flex min-vh-100 align-items-center justify-content-center">
    <Spinner animation={'border'} variant={'primary'}/>
  </div>);

  return (
    <Router history={RouterHistory}>
      <div data-testid="app">
        <a href="#main-content" className="skip-to-main-content-link">Skip to main content</a>
        <div id="content" data-testid="app-main" className="container-fluid px-0">
          <MainNav />
          {apiErrorsIfAny}
          <AppRoutes />
        </div>
      </div>
      <CookiePopup />
    </Router>
  );
};

export default App;

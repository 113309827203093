import React, { useEffect, useState } from 'react';
import { Page, PageNav } from '../shared/Page';
import { useParams } from 'react-router';
import { getForm } from '../main/API';
import CareActForm from '../shared/CareActForm';
import { Secured } from '../auth/Secured';
import { schemaForArea } from '../forms/formSchemas';
import { NotFoundPage } from './errorPages';

export function AssessmentAreaPage () {

  const {formId, area} = useParams();
  const [model, setModel] = useState({});
  const [schema, setSchema] = useState({});

  useEffect(() => {
    if (setModel && formId) {
      getForm(formId)
        .then(response => {
          setModel(response.data.data);
        })
        .catch(() => {
          alert('Could not load the form');
        });
    }
  }, [setModel, formId]);

  useEffect(() => {
    setSchema(schemaForArea(area));
  }, [area]);

  if (!schema || !schema.id) return <NotFoundPage/>;

  return (
    <Page>
      <PageNav title={schema.title} goBackUrl={`/assessment/${formId}`} />
      <div className="row align-items-start space-1">

        <div className="col-md-12">
          {model && <CareActForm formId={formId} area={area} actAreaSchema={schema} model={model}/>}
        </div>

      </div>
    </Page>
  );

}

export function AssessmentAreaPageSecured () {
  return <Secured onSuccess={AssessmentAreaPage}/>;
}

/**
 * @param config {Object}
 * @param config.sections {Array}
 * @param config.sections[].id {String}
 * @param config.sections[].title {String}
 * @param config.sections[].pages {Array}
 * @param config.sections[].pages[].id {String}
 * @param config.sections[].pages[].title {String}
 * @param config.sections[].pages[].type {checkbox|video|radio|textarea}
 * @param config.sections[].pages[].options {Array}
 * @param config.sections[].pages[].required {Boolean}
 * @param config.sections[].pages[].nextPage {Array}
 * @param config.sections[].pages[].nextPage[].options {Array}
 * @param config.sections[].pages[].nextPage[].page {String}
 * @param model {Object}
 */
export function getAvailableFormPath(config, model = {}) {
  const path = [];
  let page = [0, 0];
  while (page.length) {
    path.push(page);
    page = getNextPage(page[0], page[1], config, model);
  }
  return path;
}

export function flattenSections(path) {
  return path.reduce((memo, pathEntry) => {
    const [sectionIndex, pageIndex] = pathEntry;
    const section = memo[sectionIndex] || [];
    section[0] = sectionIndex;
    if (!section[1]) {
      section[1] = [];
    }
    section[1].push(pageIndex);
    memo[sectionIndex] = section;
    return memo;
  }, [])
    .filter(Boolean);
}

function findSectionIndex(sections, id) {
  let nextSectionIndex = -1;
  for(let i = 0; i < sections.length; i++) {
    if (sections[i].id === id) {
      nextSectionIndex = i;
      break;
    }
  }
  return nextSectionIndex;
}

function findPageIndex(pages, id) {
  let nextPageIndex = -1;
  for(let i = 0; i < pages.length; i++) {
    if (pages[i].id === id) {
      nextPageIndex = i;
      break;
    }
  }
  return nextPageIndex;
}

/**
 * Return path to the next page e.g.: [0,3]
 */
export function getNextPage(currentSectionIndex, currentPageIndex, config, model) {
  const sections = config.sections;
  const section = sections[currentSectionIndex];

  if (!section) return [];

  const sectionIdentifier = section.id;
  const pages = section.pages;
  const page = pages && pages[currentPageIndex];

  if (!page) return [];

  const pageIdentifier = page.id;
  const nextPage = page.nextPage;
  let nextPageIndex = -1;
  if (nextPage && nextPage.length === 1) {
    const nextPageId = nextPage[0].page;
    nextPageIndex = findPageIndex(pages, nextPageId);
  } else if (nextPage && nextPage.length > 1) {
    const currentPageValue = model && model[sectionIdentifier] && model[sectionIdentifier][pageIdentifier];
    nextPage.forEach(nextPage => {
      if (nextPage.options && nextPage.options[0] === currentPageValue) {
        nextPageIndex = findPageIndex(pages, nextPage.page);
      }
    });
  }

  if (nextPageIndex < 0) {
    if (section.nextSection && section.nextSection.length) {
      const matchingConditionalSection = section.nextSection.find(nextSectionCondition => {
        const conditionFieldValue = model[nextSectionCondition.given];
        const containsValue = nextSectionCondition.contains != null && Array.isArray(conditionFieldValue) && conditionFieldValue.indexOf(nextSectionCondition.contains) > -1;
        const equalsValue = nextSectionCondition.equals != null && conditionFieldValue === nextSectionCondition.equals;
        return containsValue || equalsValue;
      });
      if (matchingConditionalSection) {
        return [findSectionIndex(sections, matchingConditionalSection.openSectionId), 0];
      }
    }
    return [];
  }

  return [currentSectionIndex, nextPageIndex];
}

export function canShowQuestion(question, values) {
  if (question.existsWhen) {
    const showWhen = question.existsWhen.given;
    const contains = question.existsWhen.contains;
    return values[showWhen] === contains;
  }
  else return true;
};

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FooterComponent } from '../shared/FooterComponent';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import assessmentInProgressImg from '../images/pictures/assessment-in-progress.png';
import tableChairPcImg from '../images/pictures/table-chair-pc.png';
import videoQuestionFlowImg from '../images/pictures/video-question-flow.png';
import signpostImg from '../images/pictures/signpost.png';

export function HomePage() {
  const intl = useIntl();
  return <>
    <div role="main" id="main-content" tabIndex="-1">
      <div className="container space-2">
        <div className="row justify-content-lg-between align-items-lg-center">
          <div className="col-sm-10 col-lg-5 mb-7 mb-lg-0">
            <img className="img-fluid" src={assessmentInProgressImg} alt="Assessment"/>
          </div>

          <div className="col-lg-6">
            <div className="mb-5">
              <h1 className="display-5 mb-3">
                Let's talk about you and your&nbsp;
                <span className="text-primary font-weight-bold">day-to-day life</span>
              </h1>
              <p className="lead">This supported self assessment offers online advice, <a href={intl.formatMessage({id: "resources.link"})}>connecting you to resources and support</a> to help you live your life independently.</p>
            </div>

            <div className="d-sm-flex align-items-sm-center flex-sm-wrap">
              <Link className="btn btn-primary btn-lg mb-2" to="/assessment"><FormattedMessage id="nav.assessments.start" /></Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container space-1">
        <div className="row justify-content-lg-between align-items-lg-center">

          <div className="col-lg-6 order-1 order-lg-0">
            <div className="mb-5">
              <h2 className="mb-3">Animated videos to get you thinking</h2>
              <p className="lead">Watch animated videos with real-life examples, before telling us about your life and where you need some further advice or support.</p>
            </div>
          </div>
          <div className="col-sm-10 col-lg-5 mb-7 mb-lg-0 order-0 order-lg-1">
            <img className="img-fluid" src={videoQuestionFlowImg} alt="flow from video to question"/>
          </div>

        </div>
      </div>

      <div className="container space-1">
        <div className="row justify-content-lg-between align-items-lg-center">

          <div className="col-sm-10 col-lg-5 mb-7 mb-lg-0">
            <img className="img-fluid" src={tableChairPcImg} alt="Table and chair"/>
          </div>

          <div className="col-lg-6">
            <div className="mb-5">
              <h2 className="mb-3">Available round the clock</h2>
              <p className="lead">Access advice and start the conversation with us, at your convenience and from the comfort of your home.</p>
            </div>
          </div>

        </div>
      </div>

      <div className="container space-1">
        <div className="row justify-content-lg-between align-items-lg-center">

          <div className="col-lg-6 order-1 order-lg-0">
            <div className="mb-5">
              <h2 className="mb-3">Information and advice</h2>
              <p className="lead">We'll direct you to relevant information, advice and guidance services.</p>
            </div>
          </div>
          <div className="col-sm-10 col-lg-5 mb-7 mb-lg-0 order-0 order-lg-1">
            <img className="img-fluid" src={signpostImg} alt="signpost"/>
          </div>

        </div>
      </div>
    </div>
    <FooterComponent />
  </>;
}

import React from 'react';
import { Notifier } from '@airbrake/browser';

/**
 * To be used in tests and when no key,id present
 * @param environment {String}
 * @constructor
 */
function NotifierStub (environment) {
  this.notify = (payload) => {
    console.log("Airbrake stub notify", payload, 'for airbrake environment', environment);
    return Promise.resolve();
  };
}

/**
 * @param projectId {String|Number}
 * @param projectKey {String}
 * @param environment {String}
 * @return {NotifierStub|Notifier}
 */
export const buildAirbrakeNotifier = (projectId, projectKey, environment) => {
  if (!projectId || !projectKey || !environment || environment === 'test') {
    return new NotifierStub(environment);
  }
  return new Notifier({ projectId, projectKey, environment });
};

/**
 * Reusable notifier instance
 * @type {NotifierStub|Notifier}
 */
export const notifier = buildAirbrakeNotifier(
  process.env.REACT_APP_AIRBRAKE_PROJ_ID,
  process.env.REACT_APP_AIRBRAKE_PROJ_KEY,
  process.env.REACT_APP_AIRBRAKE_ENV);

/**
 * Utility to be used anywhere in components when handling errors
 * @param error {Error}
 * @param [info] {ErrorInfo}
 */
export const sendError = (error, info) => {
  return notifier.notify({
    error: error,
    params: {info: info}
  }).catch(e => {
    console.log("Could not send error to Airbrake", e);
  });
};

/**
 * Catch-all component, will notify when nested components
 * fail by throwing exceptions.
 * https://airbrake.io/docs/installing-airbrake/installing-airbrake-in-a-react-app/
 * https://reactjs.org/docs/error-boundaries.html
 */
export class AirbrakeErrorBoundary extends React.Component {

  componentDidCatch(error, info) {
    // Send error to Airbrake
    sendError(error, info);
  }

  render() {
    return this.props.children;
  }
}

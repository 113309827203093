import React from 'react';
import { ReactComponent as NavBackArrowSvg } from '../images/icons/nav-left-arrow.svg';
import { ReactComponent as ArrowRightSvg } from '../images/icons/right-arrow.svg';
import { ReactComponent as InfoCircleSvg } from '../images/icons/info-circle.svg';
import { ReactComponent as VideoSvg } from '../images/icons/video.svg';
import { ReactComponent as DotSvg } from '../images/icons/dot.svg';
import { ReactComponent as DotHoleSvg } from '../images/icons/dot-hole.svg';
import { ReactComponent as DotSmallSvg } from '../images/icons/dot-small.svg';
import { ReactComponent as ExternalLinkSvg } from '../images/icons/external-link.svg';

const defaultIconHeight = 20;

export const ArrowRight = ({ size, ...rest }) => <ArrowRightSvg height={size || defaultIconHeight} width={size || defaultIconHeight} {...rest} />;
export const InfoCircle = ({ size, ...rest }) => <InfoCircleSvg height={size || defaultIconHeight} width={size || defaultIconHeight} {...rest} />;
export const NavBackArrow = ({ size, ...rest }) => <NavBackArrowSvg height={size || defaultIconHeight} width={size || defaultIconHeight} {...rest} />;
export const Video = ({ size, ...rest }) => <VideoSvg height={size || defaultIconHeight} width={size || defaultIconHeight} {...rest} />;
export const Dot = ({ size, ...rest }) => <DotSvg height={size || defaultIconHeight} width={size || defaultIconHeight} {...rest} />;
export const DotHole = ({ size, ...rest }) => <DotHoleSvg height={size || defaultIconHeight} width={size || defaultIconHeight} {...rest} />;
export const DotSmall = ({ size, ...rest }) => <DotSmallSvg height={size || defaultIconHeight} width={size || defaultIconHeight} {...rest} />;
export const ExternalLink = ({ size, ...rest }) => <ExternalLinkSvg height={size || defaultIconHeight} width={size || defaultIconHeight} {...rest} />;

import React from 'react';
import {Page, PageNav} from "../shared/Page";

export function CookiePolicyPage () {
  return <>

    <Page>
      <PageNav title="nav.cookies"/>
      <div className="space-1 row">
        <div className=" col-lg-8">

          <p>Protecting your personal information is important to us. We don't use any cookies on our website that contain information which identifies you personally. However, we do use cookies on our website to help make your visit more effective. This policy explains how and why we use cookies.</p>

          <h2 className="h4 card-title">What cookies are and what information we gather</h2>

          <p>Cookies are files saved on your phone, tablet or computer when you visit a website. When you visit the website again, the cookie allows that particular website to recognise your browser. Cookies may store user preferences and other information.</p>
          <p>We use cookies to store information about how you use the BetterCare Support platform, such as the pages you visit.</p>

          <h2 className="h4 card-title">Control your cookies</h2>
          <h3 className="h5 card-title">AboutCookies.org</h3>
          <p>Full instructions on how to manage your cookies in all the main web browsers can be found on the <a href="https://aboutcookies.org">aboutcookies.org</a> website.</p>
          <h3 className="h5 card-title">How do I disable cookies?</h3>
          <p>If you want to disable cookies, you need to change your website browser settings. How to do this will depend on the browser you use. Find out how to manage cookies on popular browsers:</p>
          <ul>
            <li><a href="https://support.google.com/accounts/answer/61416">Google Chrome</a></li>
            <li><a href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd">Microsoft Edge</a></li>
            <li><a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop">Mozilla Firefox</a></li>
            <li><a href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">Microsoft Internet Explorer</a></li>
            <li><a href="https://help.opera.com/en/latest/web-preferences/#cookies">Opera</a></li>
            <li><a href="https://support.apple.com/en-us/HT201265">Apple Safari</a></li>
          </ul>
          <h3 className="h5 card-title">What happens if I disable cookies?</h3>
          <p>This depends on which cookies you disable, but in general the site may not operate properly if cookies are switched off. If you disable all cookies, you may not be able to complete a transaction on this site.</p>

          <h2 className="h4 card-title">How we use cookies</h2>
          <p>When you visit the BetterCare Support platform, the pages you see are downloaded to your web browsing device, along with a cookie. Cookies enable us to do useful things like find out whether you have visited our website before.</p>
          <p>Some online services on our website use cookies for a specific reason. Without cookies, you will not be able to use many of these services. When using these services no personal information is stored inside a cookie.</p>
          <p>Information supplied by cookies can help us to analyse information about our visitors, which helps us to provide you with a better service, including content that better meets your needs.</p>
          <h3 className="h5 card-title">Third party cookies</h3>
          <p>When visiting our website, you may also notice some cookies that are related to content from other websites. When you visit a page with content embedded from another site you may be presented with cookies from these other websites. We do not control these 'third party' cookies. You should check the other websites for more information about their cookies.</p>
          <h2 className="h4 card-title">Cookies on this website</h2>
          <h3 className="h5 card-title">Strictly necessary cookies</h3>
          <p>These essential cookies do things like remember your progress through a form (for example a care and support assessment). They also remember the choice you make about acceptable cookie usage in the cookie notice banner on the BetterCare Support homepage.</p>
          <p>They always need to be on.</p>
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Purpose</th>
                <th>Expires</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><code>cookieConsentPresent</code></td>
                <td>Remembers when you've seen the cookie consent banner</td>
                <td>365 days</td>
              </tr>
              <tr>
                <td><code>allow_google_analytics</code></td>
                <td>Saves your Google Analytics cookie consent settings</td>
                <td>1 year</td>
              </tr>
              <tr>
                <td><code>clientCallbackUrl</code></td>
                <td>Redirects to the client after login</td>
                <td>N/A</td>
              </tr>
              <tr>
                <td><code>pageBeforeAuthRedirect</code></td>
                <td>Remembers the last visited page before login</td>
                <td>N/A</td>
              </tr>
              <tr>
                <td><code>token</code></td>
                <td>JSON Web Token used to access API endpoints</td>
                <td>N/A</td>
              </tr>
              <tr>
                <td><code>react-avatar/failing</code></td>
                <td>Locally stores your avatar image</td>
                <td>N/A</td>
              </tr>
              <tr>
                <td><code>user</code></td>
                <td>Locally stores your authenticated information</td>
                <td>N/A</td>
              </tr>
            </tbody>
          </table>
          <h3 className="h5 card-title">Cookies that measure website use</h3>
          <p>We use Google Analytics cookies to measure how you use BetterCare Support.</p>
          <p>These cookies collect information about:</p>
          <ul>
            <li>how you got to the BetterCare Support site</li>
            <li>the pages you visit and how long you spend on each page</li>
            <li>what you click on while you're visiting the BetterCare Support site</li>
          </ul>
          <p>These cookies do not collect or store any personal information. We do not allow Google to use or share the data about how you use BetterCare Support.</p>
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Purpose</th>
                <th>Expires</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><code>_ga</code></td>
                <td>These help us count how many people visit the site by tracking if you’ve visited before</td>
                <td>1 year</td>
              </tr>
              <tr>
                <td><code>_gid</code></td>
                <td>These help us count how many people visit the site by tracking if you’ve visited before</td>
                <td>24 hours</td>
              </tr>
              <tr>
                <td><code>_ga_HER84MXQG8</code></td>
                <td>Used by Google Analytics to find and track an individual session with your device</td>
                <td>1 year</td>
              </tr>
            </tbody>
          </table>
          <p>You can choose to reject or accept the setting of Google Analytics cookies in the cookie notice banner on the BetterCare Support homepage or by installing the <a href="https://tools.google.com/dlpage/gaoptout">Google Analytics Opt-out Browser Add-on</a>.</p>
          <p>You can read Google’s overview of <a href="https://support.google.com/analytics/answer/6004245">data practices within Google Analytics</a>.</p>
        </div>
      </div>
    </Page>
  </>;
}

import React, { useEffect, useState } from 'react';
import { useAuthContext } from './AuthContextProvider';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';

export const Secured = ({ onSuccess: Component, ...rest }) => {

  const INACTIVITY_MAX_MILLISECONDS = 10000;
  const { initializing, isAuthenticated, loginWithRedirect } = useAuthContext();
  const [isTooLong, setIsTooLong] = useState(false);
  const [isViewLoading, setViewLoading] = useState(true);
  const [hasScope, setHasScope] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      // no need to rerender component along with children
      // by updating state if all was loaded already
      if (initializing) {
        setIsTooLong(true);
      }
    }, INACTIVITY_MAX_MILLISECONDS);
    return () => {
      clearTimeout(timeout);
    };
  });

  /**
   * setViewLoading is used as one of the methods is async and
   */
  useEffect(() => {
    if (!initializing) {
      setHasScope(isAuthenticated);
      setViewLoading(false);
      setIsTooLong(false); // reset if was set to avoid warnings
    } else {
      setViewLoading(true);
    }
  }, [initializing, isAuthenticated]);

  let redirectTimeout = null;
  /**
   * use timeout to avoid flickering screen
   * and make sure user understands what is happening
   * it also helps to hit back and jump over this screen instead of being stuck in the loop
   */
  function redirectToLogin () {
    if (redirectTimeout) {
      clearTimeout(redirectTimeout);
      redirectTimeout = null;
    }
    redirectTimeout = setTimeout(() => {
      loginWithRedirect();
    }, 2000);
  }

  const loadingTooLongView = (<p className="alert alert-warning">Authentication check takes too long to complete</p>);
  const notAuthenticatedView = (<div id="not-authenticated" className="container my-3 p-5 border border-light">
    <div className="row">
      <div className="col-sm-6 offset-sm-3">
        <h1 className="h2 text-center">
          Authenticating
        </h1>
        <p className="text-muted text-center">
          Redirecting to login/signup page...
        </p>
        <div className="d-flex align-items-center justify-content-center">
          <Spinner animation={'border'} variant={'primary'}/>
        </div>
      </div>
    </div>
  </div>);
  const notAuthorizedView = (<div className="container my-3 p-5 border border-light">
    <div className="row">
      <div className="col-sm-6 offset-sm-3">
        <h1 className="h2">
          Not authorized
        </h1>
        <p className="text-muted">
          You are not authorized to access this part. Either use other details when logging in or ask administrators to give
          you access.
        </p>
      </div>
    </div>
  </div>);

  let renderOutput;
  if (isViewLoading) {
    renderOutput = <div className="container my-3">{!isTooLong ? <Spinner animation="border" /> : loadingTooLongView}</div>;
  } else if (hasScope) {
    renderOutput = <Component {...rest} />;
  } else if (isAuthenticated) {
    renderOutput = notAuthorizedView;
  } else {
    renderOutput = notAuthenticatedView;
    redirectToLogin();
  }

  return (renderOutput);
};

Secured.propTypes = {
  onSuccess: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired
};

import React, { useEffect, useState } from 'react';
import { Secured } from '../auth/Secured';
import { useParams } from 'react-router';
import { getForm, remoteFormSubmit } from '../main/API';
import { Page, PageNav } from '../shared/Page';
import { cleanDirtyFields } from '../forms/formSchemas';
import { generateFormHtml, Preview } from '../forms/MainFormForPdf';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import RouterHistory from '../main/RouterHistory';

export const AssessmentPreviewPage = () => {

  const {formId} = useParams();
  const [model, setModel] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    getForm(formId).then(response => {
      setModel(cleanDirtyFields(response.data.data));
    }).catch(e => {
      console.error(e);
      alert(JSON.stringify(e));
    });
  }, [formId]);

  const submitAssessment = () => {
    if (model.submittedFields) {
      alert("This assessment has already been submitted and can no longer be amended.");
      return;
    }

    const values = JSON.parse((model && model.dirtyFields) || '{}');
    setHasSubmitted(true);
    remoteFormSubmit(formId, {
      formModel: values,
      dirtyModel: values,
      pdfHtml: generateFormHtml(model)
    })
      .then(() => {
        RouterHistory.push(`/assessment/${formId}/submitted`);
      })
      .catch(e => {
        setHasSubmitted(false);
        console.error(e);
        alert(JSON.stringify(e));
      });
  };

  return (
    <Page>
      <PageNav title="nav.assessment.preview" goBackUrl={`/assessment/${formId}`} />
      <div className="row space-1">
        <div className="col">
          <Alert variant="info">Check if your details are correct and then click submit.</Alert>
          <div className="bg-white p-3 rounded border">
            {model && <Preview storedFormData={model} />}
          </div>
          <hr />
          <Alert variant="warning">You cannot change your details after you click submit.</Alert>
          <Link data-testid="cancelbtn" className="btn btn-outline-secondary btn-lg mr-2" to={`/assessment/${formId}`}>Cancel</Link>
          <button disabled={hasSubmitted} data-testid="submitbtn" className="btn btn-primary btn-lg" onClick={() => submitAssessment()}>Submit</button>
        </div>
      </div>
    </Page>
  );
};

export function AssessmentPreviewPageSecured () {
  return <Secured onSuccess={AssessmentPreviewPage}/>;
}

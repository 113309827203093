import React from "react";

export function ExternalLinkCard({externalLink}) {
  if (!externalLink) {
    return null;
  }
  return (<a key={externalLink.url} data-testid="external-link-card" href={externalLink.url} className="card">
    <div className="card-body">
      <h5 className="h6 card-title mb-2" data-testid="external-link-card-label">{externalLink.labels.join('; ')}</h5>
      <div className="card-text mb-2 small text-muted text-truncate" data-testid="external-link-card-url">
        {externalLink.url}
      </div>
      <div className="card-text">
        {externalLink.categories.map(category => <small key={category} data-testid="external-link-card-category" className="badge badge-light">{category}</small>)}
      </div>
      <div className="d-none">
        {externalLink.areaKeys.map(k => <span key={k} data-testid={k}>{k}</span>)}
      </div>
    </div>
  </a>);
}

export function ExternalLinkListItem({externalLink}) {
  if (!externalLink) {
    return null;
  }
  return (<a key={externalLink.url} data-testid="external-link-item" href={externalLink.url} className="list-group-item">
    <span data-testid="external-link-item-label">{externalLink.labels.join('; ')}</span>
    <div className="card-text mb-2 small text-muted text-truncate" data-testid="external-link-item-url">
      {externalLink.url}
    </div>
  </a>);
}

import React, { useState, useEffect } from 'react';
import { Page, PageNav } from '../shared/Page';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { FormattedMessage, useIntl } from "react-intl";
import { getForm } from '../main/API';
import { Secured } from "../auth/Secured";
import { allSchemas, personalDetailsKey } from '../forms/formSchemas';
import { ArrowRight, InfoCircle } from '../main/Icons';
import { Alert, OverlayTrigger, Tooltip} from 'react-bootstrap';

export function AssessmentPage() {
  const intl = useIntl();
  const {formId} = useParams();
  const [form, setForm] = useState();
  const dirtyFields = form && form.dirtyFields && JSON.parse(form.dirtyFields);
  const hasGuidanceText = intl.formatMessage({id: "assessment.guidance"}) !== 'assessment.guidance';

  const AreaFormStatus = ({areaId}) => {
    if (!dirtyFields) return null;
    const areaForm = dirtyFields[areaId];
    if (!areaForm || areaForm.isComplete === undefined) return null;
    if (areaForm.isComplete)
      return(<span className="badge badge-success badge-pill mr-3">Completed</span>);
    else
      return (<span className="badge badge-danger badge-pill mr-3">Incomplete</span>);
  };

  const DetailsFormStatus = () => {
    if (form && form.dirtyFields) {
      const jform = JSON.parse(form.dirtyFields);
      if (jform[personalDetailsKey]) return(<span className="badge badge-success badge-pill mr-3">Completed</span>);
      else return null;
    }
    else return null;
  };

  useEffect(() => {
    getForm(formId)
      .then(response => setForm(response.data.data));
  }, [formId]);

  const canSubmit = () => {
    if (!dirtyFields) return false;
    let count = 0;
    allSchemas.forEach(schema => {
      if (dirtyFields[schema.id] !== undefined) {
        if (dirtyFields[schema.id].isComplete) count++;
      }
    });
    return count >= 1 && !!dirtyFields[personalDetailsKey];
  };

  return (
    <Page>
      <PageNav title="nav.assessment" goBackUrl={"/assessment"} />
      <div className="row align-items-start space-1">
        <div className="col-md-12">
          <b><FormattedMessage id="assessment.instruction1" /></b>
          <div className="list-group">
            {allSchemas.map(schema =>
              <Link key={schema.id} className="list-group-item d-flex justify-content-between align-items-center list-group-item-action" to={`/assessment/${formId}/area/${schema.id}`}>{schema.title}
                <div className="d-flex">
                  <AreaFormStatus areaId={schema.id} />
                  <ArrowRight size={20} />
                </div>
              </Link>)}
          </div>
          <div style={{float: 'right'}}>
            <Link className="btn btn-outline-secondary btn-md mt-2" to={`${formId}/suggestions?goBack=true`}>View your self-help suggestions</Link>
          </div>
          <br /><br />
          <b><FormattedMessage id="assessment.instruction2" /></b>
          <div className="list-group">
            <Link className="list-group-item d-flex justify-content-between align-items-center list-group-item-action" to={`/assessment/${formId}/submitter`}>Personal details
              <div className="d-flex">
                <DetailsFormStatus />
                <ArrowRight size={20} />
              </div>
            </Link>
          </div>
          <hr />
          {hasGuidanceText && <Alert variant="info"><FormattedMessage id="assessment.guidance" /></Alert>}
          <Link className="btn btn-outline-secondary btn-lg mr-2" to="/assessment"><FormattedMessage id="nav.btn.assessment.back" /></Link>
          {canSubmit() && <Link className="btn btn-primary btn-lg" to={`/assessment/${formId}/preview`}>Preview and submit</Link>}
          {!canSubmit() && <button disabled={true} className="btn btn-primary btn-lg">Preview and submit</button>}
          {form && !canSubmit() &&
            <OverlayTrigger placement="left" overlay={<Tooltip id="cannot-submit">You must complete at least 1 assessment area and personal details before you can submit.</Tooltip>}>
              <span role="img" aria-label="info" className="ml-2"><InfoCircle width="30" height="30" /></span>
            </OverlayTrigger>}
        </div>
      </div>
    </Page>
  );

}

export function AssessmentPageSecured() {
  return <Secured onSuccess={AssessmentPage}/>;
}

import React, { useEffect, useState } from 'react';
import { Secured } from '../auth/Secured';
import { useParams } from 'react-router';
import {getForm} from '../main/API';
import { Page, PageNav } from '../shared/Page';
import {ExternalLinkCard} from "../shared/ExternalLink";
import {suggestedExternalLinks} from "../main/ExternalLinks";
import {FormattedMessage} from "react-intl";
import {Alert, Spinner} from "react-bootstrap";
import { getURLSearchParams } from '../main/RouterHistory';

export const AssessmentSuggestionsPage = () => {

  const {formId} = useParams();
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const goBackUrl = getURLSearchParams().get('goBack') ? `/assessment/${formId}` : '/assessment';

  useEffect(() => {
    getForm(formId).then(response => {
      setSuggestions(suggestedExternalLinks([response.data.data]));
      setLoading(false);
    }).catch(e => {
      console.error(e);
      setLoading(false);
      alert(JSON.stringify(e));
    });
  }, [formId]);

  return (
    <Page>
      <PageNav title="nav.assessment.suggestions" goBackUrl={goBackUrl} />
      <div className="row space-1">
        <div className="col">
          <p className="text-muted"><FormattedMessage id="services.user.description"/></p>

          {loading && <div className="d-flex align-items-center justify-content-center">
            <Spinner animation={'border'} variant={'primary'}/>
          </div>}

          {!loading && suggestions && !!suggestions.length && <div data-testid="suggestions">
            <div className="card-columns">
              {suggestions.map(externalLink => <ExternalLinkCard key={externalLink.url} externalLink={externalLink}/>)}
            </div>
          </div>}

          {!loading && (!suggestions || !suggestions.length) && <div data-testid="nosuggestions">
            <Alert variant="info">No suggestions found</Alert>
          </div>}
        </div>
      </div>
    </Page>
  );
};

export function AssessmentSuggestionsPageSecured () {
  return <Secured onSuccess={AssessmentSuggestionsPage}/>;
}

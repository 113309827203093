import React, { Fragment, useEffect, useRef, useState } from 'react';
import RouterHistory from '../main/RouterHistory';
import { updateForm } from '../main/API';
import { getAvailableFormPath, canShowQuestion } from '../forms/formRulesRunner';
import { getNumberOfValidPages } from '../forms/formSchemas';
import { DotSmall, Video } from "../main/Icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';

export function AssessmentAreaProgress ({ actAreaSchema, formSteps, currentStepIndex }) {
  if (!formSteps || !formSteps.length) return null;

  return <ul data-testid="assessment-progress" className="mb-4 form-progress d-flex flex-row flex-wrap justify-content-center">
    {formSteps.map((step, stepIndex) => {
      const sectionIndex = step[0];
      const pageIndex = step[1];
      const section = actAreaSchema.sections[sectionIndex];
      const page = section.pages[pageIndex];
      const itemKey = `${stepIndex}-${sectionIndex}-${pageIndex}`;
      const stepLabel = page.title || section.title;
      const hasVideo = page.items.find(e => e.type === 'video');
      return (<li key={itemKey} data-testid={itemKey} className={`form-progress-dot ${stepIndex === currentStepIndex ? 'active' : ''} ${stepIndex < currentStepIndex ? 'complete' : ''}`}>
        <OverlayTrigger placement="bottom" overlay={<Tooltip id={itemKey}>{stepLabel}</Tooltip>}>
          <span role="img" aria-label={stepLabel} className="d-block">
            {hasVideo && <Video size={15} />}
            {!hasVideo && <DotSmall size={15} />}
          </span>
        </OverlayTrigger>
      </li>);
    })}
  </ul>;
}

export const CurrentPage = ({ page, currentPageValue, onChange, submitWasAttempted }) => {
  if (page.items) {
    const formItems = page.items.map((item, i) => {
      return <CurrentPageItem key={i} question={item} currentPageValue={currentPageValue || {}} onChange={onChange} submitWasAttempted={submitWasAttempted} />;
    });

    return (<div data-testid="current-page">
      {page.title !== "" && <div className="page mb-2">{page.title}</div>}
      {formItems}
    </div>);
  }
  else return null;
};

const CurrentPageItem = ({ question, currentPageValue, onChange, submitWasAttempted }) => {

  const [transcriptVisible, setTranscriptVisible] = useState(false);
  const optionChanged = (e, itemId, hasMultiValues = false) => {
    const value = e.target.value;
    if (hasMultiValues) {
      const checked = e.target.checked;
      const currentItemValue = (currentPageValue && currentPageValue[itemId]) || null;
      const values = Array.isArray(currentItemValue) ? [...currentItemValue] : [];
      const currentValIndex = values.indexOf(value);
      if (checked && currentValIndex < 0) {
        values.push(value);
      } else if (currentValIndex > -1) {
        values.splice(currentValIndex, 1);
      }
      currentPageValue[itemId] = values;
      onChange(currentPageValue);
    } else {
      currentPageValue[itemId] = value;
      onChange(currentPageValue);
    }
  };

  const toggleTranscript = (e) => {
    e.preventDefault();
    setTranscriptVisible(!transcriptVisible);
  };

  if (!canShowQuestion(question, currentPageValue)) return null;
  const currentItemValue = (currentPageValue && currentPageValue[question.id]) || null;

  if (question.type === 'video') {
    const videoSrc = 'https://www.youtube-nocookie.com/embed/' + question.options[0] + '?autoplay=1&rel=0&modestbranding=1';
    return (<>
      <div className="mb-3 question">{question.title}</div>
      <div className="video-container">
        <iframe title="YouTube Player" className="player" type="text/html" width="100%" height="100%" src={videoSrc} frameBorder="0"/>
      </div>
      {question.transcript && <>
        <div style={{marginTop: '10px', textAlign: 'right'}}>
          <button className="btn btn-secondary" onClick={toggleTranscript}>{transcriptVisible ? "Hide video transcript": "Show video transcript"}</button>
        </div>
        {transcriptVisible && <>
          <div style={{marginTop: '20px', padding: '20px', backgroundColor: '#dff0d8'}}>
            {question.transcript ? question.transcript : ""}
          </div>
          <hr style={{marginBottom: 0}} />
        </>}
      </>}
    </>);
  }

  if (question.type === 'checkbox') {
    const options = question.options;
    const checkboxes = options.map((item, index) => {
      let checked = Array.isArray(currentItemValue) && currentItemValue.indexOf(item) > -1;
      const keyIndex = question.id+index;
      return (
        <Fragment key={keyIndex}>
          <input
            className="form-check-input"
            id={keyIndex}
            type="checkbox"
            checked={checked}
            value={item}
            onChange={e => optionChanged(e, question.id, true)}/>
          <label className="form-check-label" key={'label' + keyIndex} htmlFor={keyIndex}>{item}</label><br/>
        </Fragment>
      );
    });
    return (<Fragment key={question.id}>
      <div className="form-group mb-4">
        {question.title && <div className="mb-1 page">{question.title}{question.required && '*'}</div>}
        <div className="form-check">{checkboxes}</div>
        {submitWasAttempted && question.required && (!currentItemValue || (currentItemValue && currentItemValue.length === 0)) && <div className="error-block">Required</div>}
      </div>
      {question.guidanceText && <div className="guidance-block mb-4">{question.guidanceText}</div>}
    </Fragment>);
  }

  if (question.type === 'radio') {
    const options = question.options;
    const radios = options.map((item, index) => {
      const checked = item === currentItemValue;
      const keyIndex = question.id+index;
      return (
        <Fragment key={keyIndex}>
          <input
            className="form-check-input"
            id={keyIndex}
            type="radio"
            value={item}
            checked={checked}
            name={"radiogroup" + keyIndex}
            onChange={e => optionChanged(e, question.id, false)}/>
          <label className="form-check-label" htmlFor={keyIndex}>{item}</label><br/>
        </Fragment>
      );
    });
    return <Fragment key={question.id}>
      <div className="form-group mb-4">
        {question.title && <div className="mb-1 page">{question.title}{question.required && '*'}</div>}
        <div className="form-check">{radios}</div>
        {submitWasAttempted && question.required && (!currentItemValue || (currentItemValue && currentItemValue.length === 0)) && <div className="error-block">Required</div>}
      </div>
      {question.guidanceText && <div className="guidance-block mb-4">{question.guidanceText}</div>}
    </Fragment>;
  }

  if (question.type === 'textarea') {
    return (<Fragment key={question.id}>
      <div className="form-group mb-4">
        {question.title && <label className="form-check-label page" htmlFor={question.id}>{question.title}{question.required && '*'}</label>}
        <textarea id={question.id} className="form-control" rows="2" value={currentItemValue || ""} onChange={e => optionChanged(e, question.id, false)}/>
        {submitWasAttempted && question.required && (!currentItemValue || (currentItemValue && currentItemValue.length === 0)) && <div className="error-block">Required</div>}
      </div>
      {question.guidanceText && <div className="guidance-block mb-4">{question.guidanceText}</div>}
    </Fragment>);
  }

  return <>
    <div className="mb-3 question">{question.title}</div>
    <div>Question type [{question.type}] is unsupported</div>
  </>;
};

export const CurrentSection = ({
  actAreaSchema = {},
  step = [0, 0],
  model = {},
  onSubmitExecutorChange,
  onSubmit,
  onChange,
  onError
}) => {
  const section = actAreaSchema.sections[step[0]];
  const page = section.pages[step[1]];
  const currentVal = model;
  const [answerValid, setAnswerValid] = useState(true);
  const [answer, setAnswer] = useState(currentVal);
  const [submitWasAttempted, setSubmitWasAttempted] = useState(false);

  const submitButtonRef = useRef();
  useEffect(() => {
    const doSubmit = () => {
      submitButtonRef.current && submitButtonRef.current.click();
    };
    onSubmitExecutorChange && onSubmitExecutorChange(doSubmit);
  }, [submitButtonRef, onSubmitExecutorChange]);

  useEffect(() => {
    setSubmitWasAttempted(false);
  }, [page]);

  useEffect(() => {
    setAnswerValid(true);
    setAnswer(currentVal);
  }, [currentVal]);

  const removeHiddenValues = (currentPageValue) => {
    page.items.forEach(item => {
      if (!canShowQuestion(item, currentPageValue)) {
        delete currentPageValue[item.id];
      }
    });
  };

  const hasAllValidAnswers = (currentPageValue) => {
    let answers = true;
    page.items.forEach(item => {
      if (canShowQuestion(item, currentVal)) {
        if (item.required === true) {
          if ((!currentPageValue[item.id]) || (currentPageValue[item.id].length === 0)) answers = false;
        }
      }
    });
    return answers;
  };

  const attemptSubmitForm = (evt) => {
    evt.preventDefault();
    setSubmitWasAttempted(true);
    if (!hasAllValidAnswers(answer)) {
      setAnswerValid(false);
      onError && onError();
    } else {
      setAnswerValid(true);
      onSubmit && onSubmit(answer);
    }
  };

  const handleAnswerChange = (currentPageValue) => {
    removeHiddenValues(currentPageValue);
    setAnswer(currentPageValue);
    if (hasAllValidAnswers(currentPageValue)) setAnswerValid(true);
    else setAnswerValid(false);
    onChange && onChange(currentPageValue);
  };

  return (<div data-testid="form-section" className="form-section">
    <h2>Section: {section.title}</h2>
    <div>{section.description}</div>
    <hr/>
    <form onSubmit={attemptSubmitForm}>
      <CurrentPage page={page} currentPageValue={answer} onChange={handleAnswerChange} submitWasAttempted={submitWasAttempted} />
      <button ref={submitButtonRef} data-testid="submitbtn" type="submit" className="d-none">Submit</button>
    </form>
    <br/>
    {!answerValid && submitWasAttempted && <>
      <div data-testid="form-error-msg" className="error-block">Above form has errors!</div>
      <br/>
    </>}
    {page.guidanceText && <div className="guidance-block">{page.guidanceText}</div>}
    <br />
  </div>);
};

const CareActForm = ({
  formId,
  area,
  actAreaSchema,
  model = {}
}) => {

  const formDirtyFieldsJson = model.dirtyFields || '{}';
  const formDirtyFields = JSON.parse(formDirtyFieldsJson);
  const areaDirtyFieldsJson = formDirtyFields[area] ? JSON.stringify(formDirtyFields[area]) : '{}';
  const [lastStepIndex, setLastStepIndex] = useState(0);
  const [calculatedFormSteps, setCalculatedFormSteps] = useState([]);
  const [currentStepIndex, setCurrentStepIndex] = useState(lastStepIndex);
  const [actAreaValues, setActAreaValues] = useState({});
  let sectionSubmitter = null;

  useEffect(() => {
    if (actAreaSchema && actAreaValues) {
      setCalculatedFormSteps(getAvailableFormPath(actAreaSchema, actAreaValues));
    }
  }, [setCalculatedFormSteps, actAreaSchema, actAreaValues]);

  useEffect(() => {
    let areaDirtyFields = null;
    try {
      areaDirtyFields = JSON.parse(areaDirtyFieldsJson);
      setActAreaValues(areaDirtyFields);
      setLastStepIndex(areaDirtyFields.lastStepIndex || 0);
      setCurrentStepIndex(areaDirtyFields.lastStepIndex || 0);
    } catch (_) {
      console.error('Could not parse dirtyFields', areaDirtyFieldsJson);
    }
  }, [areaDirtyFieldsJson]);

  useEffect(() => {
    if (lastStepIndex != null) {
      setCurrentStepIndex(lastStepIndex);
    }
  }, [lastStepIndex, setCurrentStepIndex]);

  const handleSectionSubmit = (newValues) => {
    if (model.submittedFields) {
      alert("This assessment has already been submitted and can no longer be amended.");
      return;
    }

    const lastSeenStepIndex = (currentStepIndex >= (calculatedFormSteps.length - 1)) ? currentStepIndex : currentStepIndex+1;
    const areaValue = { [area] : {...actAreaValues, ...newValues, lastStepIndex: lastSeenStepIndex, isComplete: (currentStepIndex >= calculatedFormSteps.length-1)} };
    const newDirtyFields = { ...formDirtyFields, ...areaValue };
    const { totalPages, validPages } = getNumberOfValidPages(newDirtyFields);
    const updatedModel = {
      ...model,
      dirtyFields: newDirtyFields,
      pageCount: totalPages,
      lastValidPage: validPages
    };

    return updateForm(updatedModel.id, updatedModel)
      .then((resp) => {
        const udf = JSON.parse(resp.data.data.dirtyFields);
        const updatedDirtyFields = udf[area];
        const updatedPath = getAvailableFormPath(actAreaSchema, updatedDirtyFields);
        if (currentStepIndex >= (updatedPath.length - 1)) {
          RouterHistory.push(`/assessment/${formId}`);
        } else {
          setActAreaValues(updatedDirtyFields);
          setCalculatedFormSteps(updatedPath);
          setCurrentStepIndex(currentStepIndex + 1);
          window.scrollTo(0,0);
        }
      })
      .catch(e => {
        console.error(e);
        alert(JSON.stringify(e));
      });
  };

  const handleSectionChange = (val) => {
    setActAreaValues({ ...actAreaValues, ...val });
  };

  const goBack = () => {
    if (model.submittedFields) {
      if (currentStepIndex > 0) {
        setCurrentStepIndex(currentStepIndex - 1);
        window.scrollTo(0,0);
      } else {
        RouterHistory.push(`/assessment/${formId}`);
      }
      return;
    }

    const lastSeenStepIndex = (currentStepIndex > 0) ? currentStepIndex-1 : currentStepIndex;
    const updatedDirtyFields = { [area] : {...actAreaValues, lastStepIndex: lastSeenStepIndex, isComplete: false} };
    const newDirtyFields = { ...formDirtyFields, ...updatedDirtyFields };
    const { totalPages, validPages } = getNumberOfValidPages(newDirtyFields);
    const updatedModel = {
      ...model,
      dirtyFields: newDirtyFields,
      pageCount: totalPages,
      lastValidPage: validPages
    };

    return updateForm(updatedModel.id, updatedModel)
      .then(() => {
        if (currentStepIndex > 0) {
          setCurrentStepIndex(currentStepIndex - 1);
          window.scrollTo(0,0);
        } else {
          RouterHistory.push(`/assessment/${formId}`);
        }
      })
      .catch(e => {
        console.error(e);
        alert(JSON.stringify(e));
      });
  };

  const isVideoPage = (stepIndex) => {
    const formStep = calculatedFormSteps[stepIndex];
    if (!formStep || !formStep.length) return false;
    const sectionIndex = formStep[0];
    const pageIndex = formStep[1];
    const section = actAreaSchema.sections[sectionIndex];
    const page = section.pages[pageIndex];
    return page.items.some(e => e.type === 'video');
  };

  return (<div data-testid="area-form" className="row">
    <div className="col-12">
      <AssessmentAreaProgress actAreaSchema={actAreaSchema} formSteps={calculatedFormSteps} currentStepIndex={currentStepIndex}/>
    </div>
    <div className="col-lg-8 offset-lg-2">
      <div className="bg-white p-3 rounded border">
        <CurrentSection step={calculatedFormSteps[currentStepIndex]} actAreaSchema={actAreaSchema} model={actAreaValues || {}} onSubmit={val => handleSectionSubmit(val)} onSubmitExecutorChange={fn => sectionSubmitter = fn} onChange={handleSectionChange}/>
        <div className="d-flex justify-content-between">
          <button data-testid="previous-btn" className="btn btn-outline-secondary btn-lg" onClick={goBack}>
            { currentStepIndex > 0 ? isVideoPage(currentStepIndex - 1) ? <FormattedMessage id="nav.btn.previous.video"/> : <FormattedMessage id="nav.btn.previous"/> : <FormattedMessage id="nav.btn.cancel"/> }
          </button>
          <button data-testid="next-btn" className="btn btn-primary btn-lg ml-2" onClick={() => sectionSubmitter && sectionSubmitter()}>
            {isVideoPage(currentStepIndex) ? <FormattedMessage id="nav.btn.continue.questions"/> : <FormattedMessage id="nav.btn.next"/>}
          </button>
        </div>
      </div>
    </div>
  </div>);
};

export default CareActForm;

import { flavour } from '../config';
const { flavourLinks } = flavour;

export class ExternalLink {
  constructor(url) {
    this.url = url;
    this.areaKeys = [];
    this.categories = [];
    this.labels = [];
  }

  addAreaKey(areaKey) {
    if (this.areaKeys.indexOf(areaKey) < 0) {
      this.areaKeys.push(areaKey);
    }
  }

  addCategory(category) {
    if (this.categories.indexOf(category) < 0) {
      this.categories.push(category);
    }
  }

  addLabel(label) {
    if (this.labels.indexOf(label) < 0) {
      this.labels.push(label);
    }
  }
}

export function allExternalLinks() {
  const uniqueLinks = Object.keys(flavourLinks)
    .reduce((memo, areaKey) => {
      flavourLinks[areaKey].forEach(({ category, label, url }) => {
        memo[url] = memo[url] || new ExternalLink(url);
        memo[url].addAreaKey(areaKey);
        memo[url].addCategory(category);
        memo[url].addLabel(label);
      });
      return memo;
    }, {});
  return Object.values(uniqueLinks);
}

export function allCategorizedExternalLinks() {
  return allExternalLinks()
    .reduce((memo, link) => {
      link.categories.forEach(category => {
        memo[category] = memo[category] || [];
        memo[category].push(link);
      });
      return memo;
    }, {});
}

/**
 * @param forms {Object[]}
 * @returns {Set<String>}
 * @private
 */
export function _formsToAreaIds(forms) {
  return forms.reduce((memo, formData) => {
    const formValues = formData.submittedFields ? JSON.parse(formData.submittedFields) : formData.dirtyFields ? JSON.parse(formData.dirtyFields) : {};
    return new Set([...memo, ...Object.keys(formValues)]);
  }, new Set());
}

export function suggestedExternalLinks(forms) {
  const allLinks = allExternalLinks();
  if (!forms || !forms.length) {
    return [];
  }
  const usedAreaIdsSet = _formsToAreaIds(forms);
  return allLinks.filter(link => link.areaKeys.some(it => usedAreaIdsSet.has(it)));
}

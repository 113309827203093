import { useAuthContext } from './AuthContextProvider';
import React, { useState } from 'react';
import { linkMobilePhone, unlinkMobilePhone, verifyMobilePhone } from '../main/API';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import { Alert, Button, Card, Form, InputGroup } from 'react-bootstrap';
import { PromptModal } from '../shared/Modal';

export const LinkPhoneCard = () => {
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [phone, setPhone] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const [needsVerification, setNeedsVerification] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");

  const handleLinkPhoneSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      linkMobilePhone(phone, year, month, day)
        .then(res => {
          if (res.data.confirmed) {
            setSuccessMessage("Your mobile phone is linked");
            setNeedsVerification(false);
          } else {
            setSuccessMessage(null);
            setNeedsVerification(true);
          }
          setErrorMessage(null);
        })
        .catch(e => {
          setSuccessMessage(null);
          setErrorMessage(parseAxiosError(e, 'Failed to retrieve results from server').getMessage());
        });
    }
    setValidated(true);
  };

  const handleVerificationSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      verifyMobilePhone(confirmationCode)
        .then(() => {
          setSuccessMessage("Successfully linked mobile phone number with your account");
          setErrorMessage(null);
        })
        .catch(e => {
          setSuccessMessage(null);
          setErrorMessage(parseAxiosError(e, 'Failed to retrieve results from server').getMessage());
        });
    }
    setValidated(true);
  };

  return (<Card>
    <Card.Body>
      <Card.Title>Link your mobile</Card.Title>
      <Card.Text>Registering your mobile phone number and date of birth gives you another way to access your
        account.</Card.Text>
      {!successMessage && !needsVerification && <Form noValidate validated={validated} onSubmit={handleLinkPhoneSubmit}>
        <Form.Group controlId="mobile">
          <Form.Label>Mobile phone number</Form.Label>
          <Form.Control required type="number" placeholder="Enter number" value={phone} onChange={evt => setPhone(evt.target.value)}/>
        </Form.Group>
        <Form.Group controlId="date-day">
          <Form.Label>Date of birth</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control aria-label="Day" id="date-day" required type="number" min="1" max="31" placeholder="DD" value={day} onChange={evt => setDay(evt.target.value)}/>
            <Form.Control aria-label="Month" id="date-month" required type="number" min="1" max="12" placeholder="MM" value={month} onChange={evt => setMonth(evt.target.value)}/>
            <Form.Control aria-label="Year" id="date-year" required type="number" min="1800" max="2100" placeholder="YYYY" value={year} onChange={evt => setYear(evt.target.value)}/>
          </InputGroup>
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>}

      {!successMessage && needsVerification && <Alert variant="info">Please verify your phone number</Alert>}
      {!successMessage && needsVerification &&
      <Form noValidate validated={validated} onSubmit={handleVerificationSubmit}>
        <Form.Group controlId="code">
          <Form.Label>Enter the 6-digit code</Form.Label>
          <Form.Control required type="number" placeholder="Enter code" value={confirmationCode} onChange={evt => setConfirmationCode(evt.target.value)}/>
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
        <Button variant="outline-secondary" type="button" onClick={() => {
        }}>
          Re-send code or change number
        </Button>
      </Form>}

      {successMessage && <Alert variant="success" className="mt-3">{successMessage}</Alert>}
      {errorMessage && <Alert variant="danger" className="mt-3">{errorMessage}</Alert>}
    </Card.Body>
  </Card>);
};

export const AccountPhoneLinkCard = () => {

  const {user} = useAuthContext();
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);

  const deletePhoneProfile = () => {
    unlinkMobilePhone()
      .then(() => {
        setSuccessMessage("Successfully removed mobile phone number from your account");
        setErrorMessage(null);
        setIsConfirmVisible(false);
      })
      .catch(e => {
        setSuccessMessage(null);
        setErrorMessage(parseAxiosError(e, 'Failed to retrieve results from server').getMessage());
        setIsConfirmVisible(false);
      });
  };

  if (user.mobilePhoneNumber) return (<Card>
    <Card.Body>
      <Card.Title>Mobile phone login</Card.Title>
      <Card.Text>Phone number: {user.mobilePhoneNumber}</Card.Text>
      <Button variant="danger" size={"sm"} onClick={() => setIsConfirmVisible(true)}>Remove</Button>

      {successMessage && <Alert variant="success" className="mt-3">{successMessage}</Alert>}
      {errorMessage && <Alert variant="danger" className="mt-3">{errorMessage}</Alert>}

      {isConfirmVisible &&
      <PromptModal title="Are you sure?" text="It will be removed! Operation cannot be undone." onCancel={() => setIsConfirmVisible(false)} onSuccess={() => deletePhoneProfile()}/>}

    </Card.Body>
  </Card>);

  return <LinkPhoneCard/>;

};

import React from 'react';
import { Page, PageNav } from '../shared/Page';
import { Secured } from '../auth/Secured';
import { AccountDetailsCard } from '../auth/AccountDetailsCard';
import { AccountPhoneLinkCard } from '../auth/AccountPhoneLinkCard';
import { AccountDeleteCard } from '../auth/AccountDeleteCard';
import { AccountGeneratePinCard } from '../auth/AccountGeneratePinCard';

function AccountDefault() {
  return (
    <Page>
      <PageNav title="nav.account"/>
      <div className="row align-items-start space-1">
        <div className="col-md-6 mb-3">
          <AccountDetailsCard />
          <AccountGeneratePinCard />
        </div>
        <div className="col-md-6">
          <AccountPhoneLinkCard />
          <AccountDeleteCard />
        </div>
      </div>
    </Page>
  );
}

export function AccountDefaultSecured() {
  return <Secured onSuccess={AccountDefault}/>;
}

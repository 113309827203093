import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {ExternalLink} from "../main/Icons";
import { viewAccountPage } from '../auth/authClient';

export const FooterComponent = () => (
  <footer className="d-print-none mb-3 mt-5 small">
    <hr />
    <div className="container pb-4 pt-2">
      <div className="row align-items-center justify-content-md-between flex-column flex-md-row">
        <ul className="nav">
          <li className="nav-item">
            <Link to="/" className="nav-link footer-link">
              <FormattedMessage id="nav.home" />
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/account" onClick={(e) => {e.preventDefault(); viewAccountPage(); }} className="nav-link footer-link">
              <FormattedMessage id="nav.account" />
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/help" className="nav-link footer-link">
              <FormattedMessage id="nav.help" />
            </Link>
          </li>
          {process.env.REACT_APP_TERMS_URL && <li className="nav-item">
            <a className="nav-link footer-link" href={process.env.REACT_APP_TERMS_URL} target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="nav.terms" /> <ExternalLink size={14} />
            </a>
          </li>}
          {process.env.REACT_APP_PRIVACY_URL && <li className="nav-item">
            <a className="nav-link footer-link" href={process.env.REACT_APP_PRIVACY_URL} target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="nav.privacy" /> <ExternalLink size={14} />
            </a>
          </li>}
          <li className="nav-item">
            <Link to="/cookie-policy" className="nav-link footer-link">
              <FormattedMessage id="nav.cookies" />
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/accessibility-statement" className="nav-link footer-link">
              <FormattedMessage id="nav.accessibility" />
            </Link>
          </li>
        </ul>
        <small className="text-muted">
          <FormattedMessage id="general.version" /> {window.BUILD_VERSION}
        </small>
      </div>
    </div>
  </footer>
);

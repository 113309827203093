import React, { useState } from 'react';
import { createAgentLogin, deleteAgentLogin } from '../main/API';
import { getPINSession, setPINSession } from './authClient';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import { CommonDateTime} from '../shared/Dates';
import { Alert, Button, Card, Spinner } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';

export const AccountGeneratePinCard = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState(null);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [pinStorage, setPinStorage] = useState(getPINSession());
  const [pinDeleteId, setPinDeleteId] = useState(null);

  const handleGeneratePIN = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);
    createAgentLogin().then(response => {
      const newPin = response.data;
      let session = getPINSession();
      session.agentLogins = session.agentLogins || [];
      session.agentLogins.unshift(newPin);
      setPINSession(session);
      setPinStorage(session);
      setLoading(false);
    }).catch(e => {
      setErrorMessage(parseAxiosError(e, 'Failed to generate PIN').getMessage());
      setLoading(false);
    });
  };

  const handleDeletePrompt = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    setPinDeleteId(id);
    setIsConfirmVisible(true);
  };

  const handleDeletePIN = () => {
    if (pinDeleteId) {
      deleteAgentLogin(pinDeleteId).then(() => {
        setPinDeleteId(null);
        setIsConfirmVisible(false);
        if (getPINSession().agentLogins) {
          let updatedSession = {}; 
          updatedSession.agentLogins = getPINSession().agentLogins.filter(pin => pin.id !== pinDeleteId);
          setPINSession(updatedSession);
          setPinStorage(updatedSession);
        }
      }).catch(e => {
        setDeleteErrorMessage(parseAxiosError(e, 'Failed to delete PIN').getMessage());
      });
    };
  };

  return (<><Card data-testid="account-generate-pin-card" className="mt-4">
    <Card.Body>
      <Card.Title>Share your account</Card.Title>
      <p>You can grant temporary access to someone you trust using an 8 digit PIN.</p>
      <Button data-testid="generate-btn" variant="primary" type="button" onClick={(e) => handleGeneratePIN(e)}>
        Generate PIN
      </Button>
      {loading && <div className="p-4 d-flex align-items-left justify-content-left">
        <Spinner animation={'border'} variant={'primary'}/>
      </div>}
      {errorMessage && <Alert variant="danger" className="mt-3">{errorMessage}</Alert>}
      {pinStorage && pinStorage.agentLogins && pinStorage.agentLogins.length > 0 && <>
        <hr />
        You have {pinStorage.agentLogins.length} active {pinStorage.agentLogins.length === 1 ? 'PIN' : 'PINs'}.
        <table className="table table-striped">
          <tbody>
            {pinStorage.agentLogins.map(pin => <tr key={pin.id}>
              <td><b>{pin.token ? pin.token : '########'}</b></td>
              <td>Expires <CommonDateTime date={pin.expiry}/></td>
              <td>
                <button data-testid="delete-pin-btn" className="btn btn-outline-secondary btn-sm" type="button" onClick={(e) => handleDeletePrompt(e, pin.id)}>Delete</button>
              </td>
            </tr>)}
          </tbody>
        </table>
      </>}
    </Card.Body>
  </Card>
  {isConfirmVisible && <Modal data-testid="prompt-delete-pin-modal" size="md" centered show onHide={() => setIsConfirmVisible(false)} backdrop="static" animation={false}>
    <Modal.Header closeButton>
      <Modal.Title>
        Delete
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      Are you sure you want to delete this PIN?
      {deleteErrorMessage && <Alert variant="danger" className="mt-3">{deleteErrorMessage}</Alert>} 
    </Modal.Body>
    <Modal.Footer className="justify-content-start">
      <button type="button" data-testid="yes-btn" className="btn btn-outline-secondary btn-sm px-3" onClick={() => handleDeletePIN()}>Yes</button>
      <button type="button" data-testid="cancel-btn" className="btn btn-primary btn-sm px-3" onClick={() => setIsConfirmVisible(false)}>Cancel</button>
    </Modal.Footer>
  </Modal>}</>);
};

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { loginWithRedirect, logout } from './authClient';

const refreshPage = () => window.location.reload();

const ContextErrorPageTemplate = ({children}) => {
  return (<div className="min-vh-100 d-flex flex-column">
    <nav className="navbar app-navbar flex-grow-0 flex-fill navbar-light bg-light justify-content-start px-xl-5">
      <span className="navbar-brand mr-3">
        <FormattedMessage id="general.brand"/>
      </span>
    </nav>
    <div className="container p-3 d-flex flex-grow-1 justify-content-center flex-column">
      <div className="col-lg-8 offset-lg-2">
        {children}
      </div>
    </div>
  </div>);
};

/**
 * Details of an error are opaque and there are at least 2 choices user can make:
 * - redirect to login page
 * - logout invalid user
 */
export const Auth0ContextErrorPage = () => {
  return (<ContextErrorPageTemplate>
    <h1 className="text-center"><span role="img" aria-label="Robot">🤖</span></h1>
    <h4 className="text-center">Could not start due to authentication failure</h4>

    <div className="alert alert-warning">
      <ul className="mb-0">
        <li>You could try to <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => loginWithRedirect()}>Login</button> or <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => logout()}>Logout</button> or <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => refreshPage()}>Refresh page</button></li>
        <li>Please also make sure you are connected to the internet.</li>
        <li>If the error persists, try clearing your browser cache, removing cookies or deleting local/session storage.</li>
      </ul>
    </div>

    <p className="text-muted">Please also consider trying to load this page on other available browsers in your disposal or from another device.</p>
  </ContextErrorPageTemplate>);
};

import axios from 'axios';
import URI from 'urijs';

export const AUTH_VIEW_LOGIN = 'login';
export const AUTH_VIEW_SIGNUP = 'signUp';
const STORAGE_KEY_SESSION = 'session';
const STORAGE_KEY_TOKEN = 'token';
const STORAGE_KEY_USER = 'user';
const STORAGE_KEY_LAST_PAGE = 'pageBeforeAuthRedirect';
const AUTH_GATEWAY = process.env.REACT_APP_AUTH_GATEWAY;
const AUTH_CALLBACK_LOGIN = AUTH_GATEWAY ? `${AUTH_GATEWAY}/auth?authTokenCallbackUrl=${window.location.protocol + "//" + window.location.host + "/"}` : null;
const AUTH_CALLBACK_SIGNUP = AUTH_GATEWAY ? `${AUTH_GATEWAY}/auth/signup?authTokenCallbackUrl=${window.location.protocol + "//" + window.location.host + "/"}` : null;
const AUTH_PAGE_URL_LOGIN = `${process.env.REACT_APP_SERVER}/auth?authTokenCallbackUrl=${window.location.protocol + "//" + window.location.host}#/login?viewName=${AUTH_VIEW_LOGIN}`;
const AUTH_PAGE_URL_SIGNUP = `${process.env.REACT_APP_SERVER}/auth?authTokenCallbackUrl=${window.location.protocol + "//" + window.location.host}#/login?viewName=${AUTH_VIEW_SIGNUP}`;
const USER_DETAILS_API_URL = `${process.env.REACT_APP_SERVER}/internal/v1/userProfile`;
const TOKEN_VALIDATION_API_URL = `${process.env.REACT_APP_SERVER}/internal/validate`;

// TODO fallback to cookies
// TODO decide on local vs session storage
const saveValue = (key, val) => localStorage.setItem(key, val);
const clearValue = (key) => localStorage.removeItem(key);
const getValue = (key) => localStorage.getItem(key);
const getAndRemoveValue = (key) => {
  const val = getValue(key);
  clearValue(key);
  return val;
};

const setSession = ({token, user}) => {
  if (token) saveValue(STORAGE_KEY_TOKEN, token);
  else clearValue(STORAGE_KEY_TOKEN);
  if (user) saveValue(STORAGE_KEY_USER, JSON.stringify(user));
  else clearValue(STORAGE_KEY_USER);
};

const fetchUserDetails = (token) => {
  if (!token) throw new Error('Token is required');
  return axios.get(USER_DETAILS_API_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Cache-Control': 'no-store'
    }
  }).then(response => response.data);
};

const checkTokenValid = (token) => {
  if (!token) throw new Error('Token is required');
  return axios.post(TOKEN_VALIDATION_API_URL, {},{
    headers: {Authorization: `Bearer ${token}`}
  });
};

/**
 * Start authentication sequence
 * @param {'login'|'signUp'} viewName
 */
export const loginWithRedirect = (viewName) => {
  saveValue(STORAGE_KEY_LAST_PAGE, window.location.href);
  if (AUTH_GATEWAY) {
    if (viewName === AUTH_VIEW_SIGNUP) {
      window.location.assign(AUTH_CALLBACK_SIGNUP);
    } else {
      window.location.assign(AUTH_CALLBACK_LOGIN);
    }
  }
  else {
    if (viewName === AUTH_VIEW_SIGNUP) {
      window.location.assign(AUTH_PAGE_URL_SIGNUP);
    } else {
      window.location.assign(AUTH_PAGE_URL_LOGIN);
    }
  }
};

/**
 * Handle authentication result
 */
export const handleAuthRedirect = async () => {
  const uri = URI(window.location.href);
  const params = uri.search(true); // always returns object
  const pageToShow = getAndRemoveValue(STORAGE_KEY_LAST_PAGE) || uri.search('').toString();
  const token = params.token;
  const user = await fetchUserDetails(token);
  setSession({token, user});
  window.location.replace(pageToShow);
};

export const logout = () => {
  saveValue(STORAGE_KEY_LAST_PAGE, window.location.href);
  if (AUTH_GATEWAY) {
    const token = getToken();
    const params = [['token', token]];
    const query = new URLSearchParams(params).toString();
    setSession({});
    window.location.assign(AUTH_CALLBACK_LOGIN + '&' + query);
  }
  else {
    setSession({});
    window.location.replace(window.location.protocol + "//" + window.location.host);
  }
};

export const getToken = () => getValue(STORAGE_KEY_TOKEN);
export const hasToken = () => {
  return !!getToken();
};
export const hasValidToken = async () => {
  if (!hasToken()) return false;
  return await isTokenValid();
};
export const getUser = () => JSON.parse(getValue(STORAGE_KEY_USER));
export const isTokenValid = async () => {
  const token = getToken();
  if (!token) return false;
  try {
    await checkTokenValid(token);
    return true;
  } catch (e) {
    return false;
  }
};
export const refreshSession = async () => {
  const token = getToken();
  const user = await fetchUserDetails(token);
  setSession({token, user});
};

// view account page (legacy or auth gateway);
export const viewAccountPage = async () => {
  if (AUTH_GATEWAY) window.location.href = AUTH_GATEWAY;
  else window.location.href = '/account';
};

// Session storage for generated PIN;
function getPINStorage() {
  try {
    return sessionStorage;
  } catch (e) {
    return {};
  }
}

export const getPINSession = () => {
  const pinSessionJSONString = getPINStorage()[STORAGE_KEY_SESSION];
  if (pinSessionJSONString) return JSON.parse(pinSessionJSONString);
  else return {};
};

export const setPINSession = (session) => {
  getPINStorage()[STORAGE_KEY_SESSION] = JSON.stringify(session);
};
